import React from "react";
import styled from "styled-components";

const StyledSvg = styled.svg`
  height: 100%;
`;

const StyledPath = styled.path`
  fill: ${(p) => (p.isPrimary ? p.theme.colorPrimary : p.theme.colorText)};
`;

const Wrapper = ({ imageSize = "80px" }) => {
  return (
    <StyledSvg
      width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyledPath d="M22.4182 102C20.2281 102 18.4618 101.478 17.1195 100.434C15.7771 99.39 15.0706 98.1721 15 96.7801H19.2125C19.3538 97.4181 19.707 97.9183 20.2722 98.2808C20.8551 98.6288 21.5704 98.8028 22.4182 98.8028C23.1424 98.8028 23.7252 98.6796 24.1668 98.4331C24.626 98.1866 24.8556 97.8531 24.8556 97.4326C24.8556 96.6931 24.0343 96.1349 22.3917 95.7579L20.5637 95.3664C18.9917 95.0184 17.7553 94.4529 16.8546 93.6699C15.9538 92.8869 15.4945 91.9082 15.4769 90.7337C15.4769 89.2982 16.0597 88.16 17.2255 87.319C18.4088 86.4635 19.9808 86.0358 21.9413 86.0358C23.9195 86.0358 25.5268 86.5288 26.7631 87.5148C27.9995 88.4863 28.653 89.6027 28.7237 90.8642H24.5377C24.3434 90.2987 24.0078 89.8855 23.5309 89.6245C23.054 89.3635 22.5242 89.233 21.9413 89.233C21.2878 89.233 20.7579 89.3417 20.3517 89.5592C19.9455 89.7767 19.7335 90.0885 19.7159 90.4945C19.6982 90.9005 19.8748 91.234 20.2457 91.4949C20.6343 91.7559 21.2172 91.9734 21.9943 92.1474L24.1403 92.6042C25.7122 92.9522 26.9309 93.5249 27.7964 94.3224C28.6795 95.1199 29.1211 96.1204 29.1211 97.3238C29.1211 98.6578 28.5029 99.7743 27.2665 100.673C26.0302 101.558 24.414 102 22.4182 102Z" />
      <StyledPath d="M36.5807 101.739V86.2968H48.5028V89.6027H40.7931V92.3649H46.9132V95.6709H40.7931V98.4331H48.5028V101.739H36.5807Z" />
      <StyledPath d="M73.2289 101.739H69.5993L60.4325 92.1909V101.739H56.2201V86.2968H59.8497L68.99 95.8449V86.2968H73.2289V101.739Z" />
      <StyledPath d="M87.9857 102C85.7955 102 84.0293 101.478 82.6869 100.434C81.3446 99.39 80.6381 98.1721 80.5675 96.7801H84.7799C84.9212 97.4181 85.2745 97.9183 85.8397 98.2808C86.4225 98.6288 87.1379 98.8028 87.9857 98.8028C88.7098 98.8028 89.2927 98.6796 89.7342 98.4331C90.1934 98.1866 90.4231 97.8531 90.4231 97.4326C90.4231 96.6931 89.6018 96.1349 87.9592 95.7579L86.1311 95.3664C84.5592 95.0184 83.3228 94.4529 82.422 93.6699C81.5212 92.8869 81.062 91.9082 81.0443 90.7337C81.0443 89.2982 81.6272 88.16 82.7929 87.319C83.9763 86.4635 85.5482 86.0358 87.5088 86.0358C89.4869 86.0358 91.0942 86.5288 92.3306 87.5148C93.567 88.4863 94.2205 89.6027 94.2911 90.8642H90.1051C89.9109 90.2987 89.5753 89.8855 89.0984 89.6245C88.6215 89.3635 88.0916 89.233 87.5088 89.233C86.8553 89.233 86.3254 89.3417 85.9191 89.5592C85.5129 89.7767 85.301 90.0885 85.2833 90.4945C85.2656 90.9005 85.4423 91.234 85.8132 91.4949C86.2018 91.7559 86.7846 91.9734 87.5618 92.1474L89.7077 92.6042C91.2797 92.9522 92.4984 93.5249 93.3638 94.3224C94.247 95.1199 94.6885 96.1204 94.6885 97.3238C94.6885 98.6578 94.0703 99.7743 92.834 100.673C91.5976 101.558 89.9815 102 87.9857 102Z" />
      <StyledPath d="M102.148 101.739V86.2968H114.07V89.6027H106.361V92.3649H112.481V95.6709H106.361V98.4331H114.07V101.739H102.148Z" />
      <StyledPath d="M121.788 101.739V86.2968H126V101.739H121.788Z" />
      <StyledPath
        isPrimary
        d="M52.7357 68.058C58.8503 69.1972 65.2828 69.7589 71.5777 69.7589C77.2332 69.7589 82.8625 69.3054 88.1209 68.375V72.1115L70.4284 80.5376L52.7357 72.1115V68.058Z"
      />
      <StyledPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.096 61.1879L70.7701 38L39.7695 61.1879C40.9392 61.6482 42.1464 62.079 43.3857 62.4805C60.161 67.9138 82.8105 67.9506 97.661 62.5907C98.8592 62.1582 100.007 61.6906 101.096 61.1879ZM94.5899 60.2425L70.749 42.0133L46.5682 60.1001C54.1443 62.2687 62.8627 63.3769 71.4415 63.3769C79.893 63.3769 87.9992 62.3017 94.5899 60.2425Z"
      />
    </StyledSvg>
  );
};

export default Wrapper;
